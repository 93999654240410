<template>
  <div class="full-line full-height container" @click="bgClick">
    <div class="content_container full-height flex-col align-center" :style="style">
      <div class="search_header">{{ title }}</div>
      <div class="search_container flex-row align-center" @click.stop="">
        <img src="@/static/images/icon_search.png" alt />
        <form action="#" @submit.prevent="search">
          <input ref="input" v-model="keywords" class="text-14 default-font-color" type="search" />
        </form>
      </div>
      <div class="scroll_container">
        <cube-scroll ref="scroll">
          <div v-for="(item, index) in items" :key="index" class="option-item flex-row full-line flex-wrap" @click.stop="$emit('optionClicked', item)">
            <div class="option-title full-line text-14">{{ item.name }}</div>
            <div v-for="(ext, index2) in item.exts" :key="index2" class="option-content text-12">
              {{ ext }}
            </div>
          </div>
        </cube-scroll>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Drawer",
  props: {
    onBgClicked: Function,
    onSearch: Function,
    items: {
      type: Array,
      default: () => {
        return [
          {
            id: 1,
            name: "1",
            exts: ["2", "3", "4", "5"],
          },
        ];
      },
    },
  },
  data: function () {
    return {
      width: 375,
      height: 750,
      isShowing: false,
      keywords: "",
      title: "请选择",
    };
  },
  computed: {
    style: function () {
      if (this.isShowing) {
        return "top:" + this.height * 0.3 + "px;";
      }
      return "top:" + this.height + "px;";
    },
  },
  methods: {
    show: function () {
      this.isShowing = true;
    },
    search: function () {
      this.onSearch(this.keywords);
    },
    bgClick: function () {
      this.isShowing = false;
      setTimeout(() => {
        this.onBgClicked();
      }, 300);
    },
  },
  mounted: function () {
    this.width = document.documentElement.clientWidth;
    this.height = document.documentElement.clientHeight;
  },
};
</script>

<style scoped>
.container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.4);
}

.content_container {
  position: relative;
  background-color: #dbdbdb;
  height: 70%;
  transition: 0.3s ease-in-out;
  bottom: 0;
}
.scroll_container {
  flex: 1;
  min-height: 0;
  width: 100%;
  background-color: #ffffff;
}
.search_header {
  width: 100%;
  height: 36px;
  line-height: 36px;
  background-color: #c7c7c7;
}
.search_container {
  width: calc(100% - 40px);
  background-color: white;
  height: 30px;
  border-radius: 15px;
  margin: 8px 0;
}

.search_container img {
  flex: 0 0 16px;
  width: 16px;
  height: 16px;
  margin-left: 15px;
}

.search_container input {
  flex: 1;
  min-width: 0;
  margin-left: 9px;
  margin-right: 15px;
  outline-style: none;
  border: 0px;
  padding: 3px 0;
}

::placeholder {
  color: #999999;
}

.cancel_text {
  flex: 1;
  min-width: 0;
  text-align: center;
}
.option-item {
  border-bottom: 1px #dbdbdb solid;
  padding: 5px 10px;
  line-height: 26px;
}
.option-title {
  color: #3874f6;
  text-align: left;
}
.option-content {
  flex: 0 0 50%;
  width: 50%;
  text-align: left;
}
</style>